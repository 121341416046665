<template>
    <div>
        <card-header title="Assist/Share" icon="fa-share-square"/>

        <card-body>
            <card-list>
                <subheader-list-item title="Automatic Login Link" icon="fa-compass"/>
                <container-list-item padded>
                    <b-field label="Navigator App Redirect" label-position="inside">
                        <b-select expanded v-model="component">
                            <option value="Today">Today</option>
                            <option value="Itinerary">Trip Info: Itinerary</option>
                            <option value="Flights">Trip Info: Flights</option>
                            <option value="TripPreferences">Trip Info: Trip Preferences (Questions)</option>
                            <option value="Travel">Trip Info: Travel</option>
                            <option value="Tournament">Trip Info: Tournament</option>
                            <option value="Cities">Trip Info: Cities</option>
                            <option value="Packing">Trip Info: Packing</option>
                            <option value="FAQs">Trip Info: FAQs</option>
                            <option value="Experience">Experience</option>
                            <option value="Travelers">Fellow Travelers</option>
                            <option value="Billing">Account: Trip Cost & Payments</option>
                            <option value="Profile">Account: Traveler Profile (Photo)</option>
                            <option value="Settings">Account: Settings (Login Credentials)</option>
                            <option value="Documents">Account: Documents (Passport)</option>
                            <option value="Payment">Account: Payment Method (Update CC)</option>
                            <option value="TravelDetails">Print Itinerary</option>
                            <option value="Trips">Choose a Trip (Trip List)</option>
                        </b-select>
                    </b-field>

                    <b-field label="Selected Trip/Registration" label-position="inside">
                        <b-select expanded v-model="registration">
                            <option v-for="r in user.registrations" :value="r.id">{{r.trip.title}}</option>
                        </b-select>
                    </b-field>
                    <b-field grouped>
                        <b-input :value="shareLink" disabled expanded/>
                        <p class="control">
                            <b-button v-clipboard:copy="shareLink"><i class="fas fa-clipboard"></i></b-button>
                        </p>
                    </b-field>
                </container-list-item>
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import SeparatorListItem from "../../TIER/components/SeparatorListItem";
    import CardBody from "../../TIER/components/CardBody";
    import ContainerListItem from "../../TIER/components/ContainerListItem";

    import {client as http} from '../../http_client';

    export default {
        name: "Users",
        props: ['card', 'child', 'props'],
        components: {ContainerListItem, CardBody, SeparatorListItem, SubheaderListItem, CardList, CardHeader},
        data: function() {
            return {
                user: {
                    notes: [],
                    registrations: [],
                    documents: []
                },
                component: 'Today',
                registration: null,
            };
        },
        computed: {
            shareLink: function() {
                return 'https://app.tennisventures.net/#/share/' + this.user.shareToken + '/' + this.registration + '/' + this.component;
            }
        },
        methods: {
            loadUser: function(force) {
                this.$emit('loading', true);
                http.get('/api/users/' + this.props.userId, {force}).then(response => {
                    this.user = response.data;
                    this.registration = getDefaultTrip(this.user.registrations).id;
                    this.$emit('loading', false);
                });
            },
            reload: function() {
                this.loadUser(true);
            }
        },
        mounted() {
            this.loadUser();
        }
    };

    function getDefaultTrip(registrations) {
        const now = new Date();

        // Filter out registrations that have already ended
        const validRegistrations = registrations.filter(reg => {
            const endOn = new Date(reg.trip.end_on);
            return endOn >= now;
        });

        // Find an active registration (trip is ongoing)
        const activeRegistration = validRegistrations.find(reg => {
            const startOn = new Date(reg.trip.start_on);
            const endOn = new Date(reg.trip.end_on);
            return startOn <= now && endOn >= now;
        });

        if (activeRegistration) {
            return activeRegistration;
        }

        // Find the first upcoming registration
        const upcomingRegistration = validRegistrations.sort((a, b) => {
            const startA = new Date(a.trip.start_on);
            const startB = new Date(b.trip.start_on);
            return startA - startB;
        })[0];

        return upcomingRegistration || null; // Return null if no valid registrations found
    }
</script>
